import {
    getHeaders,
    getStorage,
    getOptionsOnlyOutput
} from '../../lib/skycity.js'

export function getConfigSplitView (curFile, outputOptions, inputSecondFiles) {
    const payload = getCodePayload(curFile, outputOptions, inputSecondFiles)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/splitView`,
        method: 'POST',
        timeout: 180000,
        // timeout: 5000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

export function getConfigSideBySide (curFile, outputOptions, inputSecondFiles) {
    const payload = getCodePayload(curFile, outputOptions, inputSecondFiles)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/sideBySide`,
        method: 'POST',
        timeout: 180000,
        // timeout: 5000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

function getCodePayload (curFile, outputOptions, curSecondFile) {
    const codePayload = {
        inputs: [
            {
                href: curFile.path,
                storage: getStorage(curFile)
            },
            {
                href: curSecondFile.path || curSecondFile.href,
                storage: getStorage(curSecondFile)
            }
        ],
        outputs: getOptionsOnlyOutput(curFile, outputOptions)
    }
    return codePayload
}
