import {
    getHeaders,
    getStorage,
    getOptionsOutput
} from '../../lib/skycity.js'

export function getConfig (curFile, outputOptions, iccProfileOption) {
    const payload = getCodePayload(curFile, outputOptions, iccProfileOption)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/artboardCreate`,
        method: 'POST',
        timeout: 90000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

function getCodePayload (curFile, outputOptions, iccProfileOption) {
    const artboard = curFile.map(file => {
        return {
            href: file.path || file.href,
            storage: getStorage(file)
        }
    })
    const codePayload = {
        options: {
            artboard: artboard
        },
        outputs: getOptionsOutput(curFile[0], outputOptions, iccProfileOption)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases () {}
