import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import Checkbox from '@react/react-spectrum/Checkbox'
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger'
import Tooltip from '@react/react-spectrum/Tooltip'
import Radio from '@react/react-spectrum/Radio'
import RadioGroup from '@react/react-spectrum/RadioGroup'
import Heading from '@react/react-spectrum/Heading'
import Well from '@react/react-spectrum/Well'
import Select from '@react/react-spectrum/Select'
import FieldLabel from '@react/react-spectrum/FieldLabel'
import { error } from '@react/react-spectrum/Toast'

import SelectInput from './SelectInput'

export default function IccProfileOption (props) {
    const {
        iccProfileOption,
        inputIccFiles,
        inputIccFile,
        dsInputIccFiles,
        dispatch
    } = useContext(SkycityContext)

    const [isProfileNameSelectVisible, setProfileNameSelectVisible] = useState(true)
    const [isSelectInputDialog, setSelectInputDialog] = useState(false)

    function changeColorModeSelection (e) {
        iccProfileOption.selected = e
        dispatch({ type: 'set-iccProfileOption', payload: { iccProfileOption: iccProfileOption } })
    }

    function changeProfileNameSelection (e) {
        const selectedImageMode = iccProfileOption.imageMode.filter(mode => mode.selected)[0].value
        const newMode = iccProfileOption.profileName[selectedImageMode].map(name => {
            name.selected = false
            if (name.value === e) {
                name.selected = true
            }
            return name
        })
        iccProfileOption.profileName[selectedImageMode] = newMode
        dispatch({ type: 'set-iccProfileOption', payload: { iccProfileOption: iccProfileOption } })
    }

    function getProfileNames () {
        const selectedImageMode = iccProfileOption.imageMode.filter(mode => mode.selected)[0].value
        return iccProfileOption.profileName[selectedImageMode]
    }
    function getDefaultProfileNames () {
        const selectedImageMode = iccProfileOption.imageMode.filter(mode => mode.selected)[0].value
        const selectedProfileNames = iccProfileOption.profileName[selectedImageMode]
        if (selectedProfileNames) {
            const selectedProfileName = selectedProfileNames.find(mode => mode.selected)
            return selectedProfileName.value
        }
        return false
    }

    function changeIccProfileOption (e) {
        const newImageMode = iccProfileOption.imageMode.map(mode => {
            mode.selected = false
            if (mode.value === e) {
                mode.selected = true
            }
            return mode
        })
        iccProfileOption.imageMode = newImageMode
        dispatch({ type: 'set-iccProfileOption', payload: { iccProfileOption: iccProfileOption } })
    }

    function isIccProfileNameDisabled () {
        if (iccProfileOption.selected) {
            const profileNames = getDefaultProfileNames()
            if (profileNames.length > 0) {
                return false
            }
        }
        return true
    }

    function changeIccProfileOptions (e) {
        switch (e) {
        case 'input':
            setSelectInputDialog(true)
            setProfileNameSelectVisible(false)
            iccProfileOption.profileFile.selected = true
            iccProfileOption.profileName.selected = false
            break
        default:
            setSelectInputDialog(false)
            setProfileNameSelectVisible(true)
            iccProfileOption.profileFile.selected = false
            iccProfileOption.profileName.selected = true
        }
        dispatch({ type: 'set-iccProfileOption', payload: { iccProfileOption: iccProfileOption } })
    }

    function getIccProfileOptions () {
        return <React.Fragment>
            <Heading className="adobe-skycity-subtitle1" variant="subtitle1">ICC Profile</Heading>
            <Well>
                <div className="adobe-skycity-options-col">
                    <div className="adobe-skycity-option">
                        <OverlayTrigger placement="right">
                            <Checkbox value={iccProfileOption.selected}
                                defaultChecked={iccProfileOption.selected}
                                onChange={ e => changeColorModeSelection(e)}></Checkbox>
                            <Tooltip variant="info">
                                {__('output_option_tooltip_imagemode')}
                            </Tooltip>
                        </OverlayTrigger>
                        <FieldLabel className="adobe-skycity-options-field" label="Image Mode" labelFor="idImageMode" position="left">
                            <OverlayTrigger placement="right">
                                <Select
                                    disabled={!iccProfileOption.selected}
                                    options={iccProfileOption.imageMode}
                                    onChange={e => changeIccProfileOption(e)}
                                    id="idImageMode" />
                                <Tooltip variant="info">
                                    {__('output_option_tooltip_imagemode')}
                                </Tooltip>
                            </OverlayTrigger>
                        </FieldLabel>
                    </div>
                    <div className="adobe-skycity-option">
                        <RadioGroup name="vertical-group" defaultSelectedValue="profileName" onChange={ e => changeIccProfileOptions(e)}>
                            <Radio label="ICCProfile Name" value="profileName" disabled={isIccProfileNameDisabled()} />
                            <Radio label="ICCProfile File" value="input" />
                        </RadioGroup>

                    </div>
                    <div className={ isProfileNameSelectVisible ? 'adobe-skycity-show' : 'adobe-skycity-hide'}>
                        <Select
                            disabled={isIccProfileNameDisabled()}
                            options={getProfileNames()}
                            defaultValue={ getDefaultProfileNames() }
                            required
                            onChange={ e => changeProfileNameSelection(e)} />
                    </div>
                    <div className={ isSelectInputDialog ? 'adobe-skycity-show' : 'adobe-skycity-hide'}>
                        <SelectInput
                            storage={['s3', 'cloudContent']}
                            actionType="set-input-icc"
                            title="Select an iccProfile file"
                            imageTypes=".icc,text/plain,application/vnd.iccprofile"
                            files={inputIccFiles}
                            file={inputIccFile}
                            dsFiles={dsInputIccFiles}/>
                    </div>
                </div>
            </Well>
        </React.Fragment>
    }

    try {
        return getIccProfileOptions()
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
