import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import Wait from '@react/react-spectrum/Wait'
import { error } from '@react/react-spectrum/Toast'
import Operation from './Operation'
import SelectInput from '../Shared/SelectInput'
import FeatureNav from '../Shared/FeatureNav'
import Step from '../Shared/Step'
import Output from '../Shared/Output'

export default function Main () {
    const {
        inputFiles,
        inputFile,
        outputFiles,
        response,
        responseLinux,
        codes,
        codesLinux,
        previewOnLinux,
        loading,
        error,
        errMsg,
        errMsgLinux,
        errorLinux
    } = useContext(SkycityContext)

    const linuxOutputFiles = outputFiles.filter(output => output.previewOnLinux === true)
    const windowsOutputFiles = outputFiles.filter(output => output.previewOnLinux === false)

    let outputElement = <Output outputFiles={windowsOutputFiles} response={response} codes={codes} errMsg={errMsg} error={error} />
    if (previewOnLinux) {
        if (!(linuxOutputFiles.length < 1 && errorLinux == false)) {
            outputElement = <div className="adobe-skycity-outputs">
                <div className="adobe-skycity-output-pane"><h1>Windows</h1><Output outputFiles={windowsOutputFiles} response={response} errMsg={errMsg} codes={codes} error={error} /></div>
                <div className="adobe-skycity-output-pane"><h1>Linux</h1><Output outputFiles={linuxOutputFiles} response={responseLinux} errMsg={errMsgLinux} codes={codesLinux} error={errorLinux} /></div>
            </div>
        }
    }

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('psactions_loading_message')}</div>
            </div>}
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        {/* **********************
                        Input
                        ********************** */}
                        <Step num={1} title={__('skycity_comp_title_input')} />
                        <SelectInput
                            storage={['as', 's3', 'cloudContent']}
                            actionType="set-input"
                            inputType="input"
                            title="Select image file(s)"
                            multiple={true}
                            files={inputFiles}
                            file={inputFile}
                            imageTypes="image/png,image/jpeg,image/vnd.adobe.photoshop,image/tif,image/tiff,document/vnd.adobe.cpsd+dcx" />
                        {/* **********************
                        Operation
                        ********************** */}
                        <Step num={2} title={__('skycity_comp_title_operation')} />
                        <Operation />
                        {/* **********************
                        Output
                        ********************** */}
                        <Step num={3} title={__('skycity_comp_title_output')} />
                        {outputElement}
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
