import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Wait from '@react/react-spectrum/Wait'

import Operation from './Operation'
import LayerInfo from './LayerInfo'

import SelectInput from '../Shared/SelectInput'
import SelectedLayersTag from '../Shared/SelectedLayersTag'
import FeatureNav from '../Shared/FeatureNav'
import Step from '../Shared/Step'
import Output from '../Shared/Output'

export default function Main () {
    const {
        inputFiles,
        inputFile,
        inputSecondFiles,
        inputSecondFile,
        outputFiles,
        response,
        codes,
        loading
    } = useContext(SkycityContext)

    try {
        return <div className="adobe-skycity-main-pane">
            { loading && <div className="adobe-skycity-modal-loading">
                <Wait size="L" />
                <div>{__('pssmartobject_loading_message')}</div>
            </div>
            }
            <div className="adobe-skycity-main">
                <div className="adobe-skycity-main-column">
                    <div className="adobe-skycity-main-feature">
                        <FeatureNav />
                        {/* **********************
                        Feature Input
                        ********************** */}
                        <Step num={1} title={__('skycity_comp_title_input')} />
                        <SelectInput
                            storage={['s3', 'cloudContent']}
                            actionType="set-input"
                            title="Select a PSD file that contains Smart Object layer(s)"
                            multiple={false}
                            files={inputFiles}
                            file={inputFile}
                            imageTypes="image/vnd.adobe.photoshop,document/vnd.adobe.cpsd+dcx,application/x-photoshop,application/octet-stream,.psb"
                            documentManifest />
                        <br />
                        <SelectedLayersTag type="smartObject"/>
                        <br />
                        <SelectInput
                            storage={['as', 's3', 'cloudContent']}
                            actionType="set-input-second"
                            title="Select file(s) to replace Smart Object layer(s)"
                            imageTypes=".cr2,.ai,.pdf,.svg,image/png,image/tiff,image/jpeg,image/vnd.adobe.photoshop,image/*,application/illustrator,application/pdf,image/svg+xml,document/vnd.adobe.cpsd+dcx"
                            multiple={true}
                            files={inputSecondFiles}
                            file={inputSecondFile} />
                        {/* **********************
                        Feature Operation
                        ********************** */}
                        <Step num={2} title={__('skycity_comp_title_operation')} />
                        <Operation />
                        {/* **********************
                        Feature Output
                        ********************** */}
                        <Step num={3} title={__('skycity_comp_title_output')} />
                        <Output outputFiles={outputFiles} response={response} codes={codes} />
                    </div>
                    {/* **********************
                    LayerInfo
                    ********************** */}
                    <div className="adobe-skycity-layer-info">
                        <LayerInfo />
                    </div>
                </div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
