import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'
import Well from '@react/react-spectrum/Well'
import Heading from '@react/react-spectrum/Heading'

import OutputOptions from '../Shared/OutputOptions'
import SelectInput from '../Shared/SelectInput'
import SelectActionByName from '../Shared/SelectActionByName'
import PreviewOnLinux from '../Shared/PreviewOnLinux'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import {
    getFilename,
    getThumbnail,
    getOutputToDownload,
    getFilenameWithoutExt
} from '../../lib/skycity.js'

import axios from 'axios'
import { getConfig, getConfigLinux } from '../../asset/code/psActions.js'

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        inputSecondFile,
        inputFontFiles,
        inputFontFile,
        outputOptions,
        iccProfileOption,
        errorOptions,
        previewOnLinux,
        psActionFile,
        dispatch
    } = useContext(SkycityContext)

    function start (curFile) {
        const config = getConfig(curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles)

        dispatch({ type: 'set-codes', payload: { config: config } })
        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        // ---------------------------
                        // Photoshop API
                        res.outputs[0]._links.renditions.forEach(rendition => {
                            files.push({
                                inputFile: curFile,
                                name: getFilename(rendition.href),
                                shortName: getFilenameWithoutExt(rendition.href),
                                path: rendition.href,
                                outputThumbnail: getThumbnail(rendition, 300),
                                output: getThumbnail(rendition),
                                outputToDownload: getOutputToDownload(rendition),
                                previewOnLinux: false
                            })
                        })
                        // ---------------------------
                        dispatch({
                            type: 'skycity-success',
                            payload: {
                                files: files,
                                selectedInputFile: files[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })

        if (previewOnLinux) {
            const configLinux = getConfigLinux(curFile, outputOptions, iccProfileOption, inputSecondFiles, psActionFile, inputFontFiles)
            dispatch({ type: 'set-codes-linux', payload: { config: configLinux } })
            apiCall(configLinux, (id, json) => {
                if (id) {
                    getStatus(id, configLinux, (res, error, errResponse) => {
                        if (error) {
                            errorOccuredLinux(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                        } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                            const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${configLinux.url}`
                            errorOccuredLinux(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                        } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                            // ---------------------------
                            // Photoshop API
                            res.outputs[0]._links.renditions.forEach(rendition => {
                                files.push({
                                    inputFile: curFile,
                                    name: getFilename(rendition.href),
                                    shortName: getFilenameWithoutExt(rendition.href),
                                    path: rendition.href,
                                    outputThumbnail: getThumbnail(rendition, 300),
                                    output: getThumbnail(rendition),
                                    outputToDownload: getOutputToDownload(rendition),
                                    previewOnLinux: true
                                })
                            })
                            // ---------------------------
                            dispatch({
                                type: 'skycity-success-linux',
                                payload: {
                                    files: files,
                                    selectedInputFile: files[0],
                                    showLayerInfoPane: true,
                                    loading: false,
                                    wait: false,
                                    response: res
                                }
                            })
                        }
                    })
                } else {
                    const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${configLinux.url}`
                    errorOccuredLinux(`${JSON.stringify(curFile)} - ${errMsg}`, json)
                }
            })
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    // const errMsg = `ERROR: ${(Object.keys(err).length === 0 && err.constructor === Object) ? JSON.stringify(err) : err}`;
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0 && inputSecondFiles && inputSecondFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function errorOccuredLinux (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured-linux', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_select_action')}</Heading>
                <br />
                <SelectInput
                    storage={['s3']}
                    actionType="set-input-second"
                    title=""
                    imageTypes=".atn,.json,application/octet-stream,application/json"
                    multiple={true}
                    files={inputSecondFiles}
                    file={inputSecondFile} />
                <br />
                <SelectActionByName />
            </Well>
            <Well>
                <Heading variant="pageTitle">{__('skycity_comp_title_select_font')}</Heading>
                <br />
                <SelectInput
                    storage={['s3']}
                    actionType="set-input-font"
                    title=""
                    imageTypes="*.*,*,.otf,.ttf,.ttc,application/x-font-otf,application/x-font-ttf,application/x-font-ttc"
                    inputType="font"
                    multiple={true}
                    files={inputFontFiles}
                    file={inputFontFile} />
            </Well>
            <br />
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']} />
            <div style={{ marginBottom: '35px' }} className="adobe-skycity-button-right">
                <div><Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button></div>
                <div style={{ float: 'right' }}><PreviewOnLinux label="Preview On Linux"/></div>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
