import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import Button from '@react/react-spectrum/Button'

import { error } from '@react/react-spectrum/Toast'
import { getConfigSplitView, getConfigSideBySide } from '../../asset/code/psSocialMediaRenditions.js'

import OutputOptions from '../Shared/OutputOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import {
    getFilename, getFilenameWithoutExt, getThumbnail, getOutputToDownload
} from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        outputOptions,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    function start (curFile, curSecondFile) {
        /* *********************************** */
        const configSplitView = getConfigSplitView(curFile, outputOptions, curSecondFile)
        dispatch({ type: 'set-codes', payload: { config: configSplitView } })
        apiCall(configSplitView, (id, json) => {
            if (id) {
                getStatus(id, configSplitView, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${configSplitView.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        // ---------------------------
                        // Photoshop API
                        res.outputs[0]._links.renditions.forEach(rendition => {
                            files.push({
                                inputFile: curFile,
                                name: getFilename(rendition.href),
                                shortName: getFilenameWithoutExt(rendition.href),
                                path: rendition.href,
                                outputThumbnail: getThumbnail(rendition, 300),
                                output: getThumbnail(rendition),
                                outputToDownload: getOutputToDownload(rendition),
                                previewSplitView: true
                            })
                        })
                        dispatch({
                            type: 'skycity-success',
                            payload: {
                                files: files,
                                selectedInputFile: files[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${configSplitView.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })

        const configSideBySide = getConfigSideBySide(curFile, outputOptions, curSecondFile)
        dispatch({ type: 'set-codes-sidebyside', payload: { config: configSideBySide } })
        apiCall(configSideBySide, (id, json) => {
            if (id) {
                getStatus(id, configSideBySide, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${configSideBySide.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        // ---------------------------
                        // Photoshop API
                        res.outputs[0]._links.renditions.forEach(rendition => {
                            files.push({
                                inputFile: curFile,
                                name: getFilename(rendition.href),
                                shortName: getFilenameWithoutExt(rendition.href),
                                path: rendition.href,
                                outputThumbnail: getThumbnail(rendition, 300),
                                output: getThumbnail(rendition),
                                outputToDownload: getOutputToDownload(rendition),
                                previewSplitView: false
                            })
                        })
                        // ---------------------------
                        dispatch({
                            type: 'skycity-success-sidebyside',
                            payload: {
                                files: files,
                                selectedInputFile: files[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${configSideBySide.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }
    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })
            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                inputSecondFiles.forEach(curSecondFile => {
                    funcArray.push(start(curFile, curSecondFile))
                })
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    // const errMsg = `ERROR: ${(Object.keys(err).length === 0 && err.constructor === Object) ? JSON.stringify(err) : err}`;
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function errorOccuredSideBySide (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured-sidebyside', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <OutputOptions imageTypes={['image/jpeg', 'image/png', 'image/vnd.adobe.photoshop', 'image/tiff']} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
