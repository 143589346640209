import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from '../SsImageCutoutOnSensei/SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import axios from 'axios'

import {
    getAssetUrn,
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders
} from '../../lib/skycity.js'

import {
    ssApiCall,
    getSenseiStatus
} from '../../lib/ccApi.js'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        serviceMode: [
            { label: 'Cutout', value: 'cutout', selected: true },
            { label: 'Mask', value: 'mask', selected: false }
        ],
        maskFormat: [
            { label: 'JPEG', value: 'jpeg', selected: true },
            { label: 'PNG', value: 'png', selected: false },
            { label: 'webP', value: 'webp', selected: false }
        ],
        outputQuality: 95,
        trim: false
    })

    function formatParams (params) {
        const mode = params.serviceMode.find((mode) => mode.selected).value
        const mask_format = params.maskFormat.find((format) => format.selected).value
        const trim = params.trim
        const quality = Number.parseInt(params.outputQuality)
        return {
            mode: mode,
            mask_format: `image/${mask_format}`,
            trim: trim,
            output_quality: quality
        }
    }

    async function start (curFile) {
        console.log('START CALLED')

        const tempURN = await getAssetUrn(`${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getTempDirectory()}`)
        const curAssetURN = await getAssetUrn(curFile.url)
        const outputFileBasename = `cutout_${uuid()}_${curFile.shortName}`
        const formattedParams = formatParams(params)
        const outputFileExtension = (formattedParams.mask_format === 'image/jpeg') ? 'jpg' : (formattedParams.mask_format == 'image/png') ? 'png' : 'webp'
        const filePath = `${curFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'ImageCutout V4 Async on CPF',
                'sensei:invocation_mode': 'asynchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Feature:autocrop:Service-e4c2aec8002943a797840574eab514eb'
                        },
                        'sensei:params': formattedParams,
                        'sensei:inputs': {
                            image_in: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': filePath
                            }
                        },
                        'sensei:outputs': {
                            mask: {
                                'repo:id': tempURN,
                                'dc:format': `${formattedParams.mask_format}`,
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileBasename}.${outputFileExtension}`
                                }
                            },
                            metadata_out: {
                                'repo:id': tempURN,
                                'dc:format': 'application/json',
                                'sensei:repoType': 'RAPI',
                                'repo:resource': {
                                    reltype: 'http://ns.adobe.com/adobecloud/rel/create',
                                    path: `${outputFileBasename}.json`
                                }
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        try {
            const response = await ssApiCall(config)

            if (response && response.status < 400) {
                const senseiStatusResponse = await getSenseiStatus(response.getResponseHeader('location'))

                if (senseiStatusResponse && senseiStatusResponse.status === '200') {
                    const outputHref = `${getTempDirectory()}/${outputFileBasename}.${outputFileExtension}`
                    const response = { ...senseiStatusResponse }

                    files.push({
                        inputFile: curFile,
                        name: getFilename(outputHref),
                        shortName: getFilenameWithoutExt(outputHref),
                        path: outputHref,
                        outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                        output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                        outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                    })
                    dispatch({
                        type: 'skycity-success',
                        payload: {
                            files: files,
                            response: response,
                            loading: false,
                            wait: false
                        }
                    })
                } else {
                    const errMsg = senseiStatusResponse.errors ? `${JSON.stringify(senseiStatusResponse.errors, null, 2)}` : `ERROR with ${config.url}`
                    errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, senseiStatusResponse)
                }
            } else {
                const errMsg = response ? `${JSON.stringify(response.response, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${errMsg}`, response)
            }
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(curFile => {
                funcArray.push(start(curFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    function checkFileType (type) {
        if (type === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
