import React, { useContext, useState } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'
import SenseiParams from './SenseiParams'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import { ssApiSyncCall } from '../../lib/ccApi.js'
import {
    getFilename,
    getFilenameWithoutExt,
    getEncodedURI,
    getTempDirectory,
    getSenseiHeaders,
    fileUpload
} from '../../lib/skycity.js'

import axios from 'axios'

const uuid = require('uuid/v4')

export default function Operation (files) {
    const {
        inputFiles,
        inputSecondFiles,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    const [params, setParams] = useState({
        refineMode: [
            { label: 'full_refinement', value: 'full_refinement', selected: true },
            { label: 'universal_refinement', value: 'universal_refinement', selected: false },
            { label: 'hair_refinement', value: 'hair_refinement', selected: false },
            { label: 'stuff_refinement', value: 'stuff_refinement', selected: false }
        ]
    })

    function formatParams (params) {
        const refine_mode = params.refineMode.find((format) => format.selected).value
        return {
            refine_mode: refine_mode
        }
    }

    async function start (curFile, curSecondFile) {
        console.log('START CALLED')
        const formattedParams = formatParams(params)
        const codePayload = {
            contentAnalyzerRequests: {
                'sensei:name': 'MaskRefinement',
                'sensei:invocation_mode': 'synchronous',
                'sensei:invocation_batch': false,
                'sensei:in_response': false,
                'sensei:engines': [
                    {
                        'sensei:execution_info': {
                            'sensei:engine': 'Classification:autocrop:Service-4f082a09314b4480b0bcdd85ac67da4e'
                        },
                        'sensei:params': {
                            mode: `${formattedParams.refine_mode}`
                        },
                        'sensei:inputs': {
                            image: {
                                'dc:format': checkFileType(curFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': `${curFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                            },
                            mask: {
                                'dc:format': checkFileType(curSecondFile.type),
                                'sensei:repoType': 'HTTP',
                                'repo:path': `${curSecondFile.url}?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                            }
                        },
                        'sensei:outputs': {
                            mask: {
                                'dc:format': 'image/png',
                                'sensei:multipart_field_name': 'outfile'

                            },
                            metadata: {
                                'dc:format': 'application/json',
                                'sensei:multipart_field_name': 'metadata'
                            }
                        }
                    }
                ]
            }
        }

        const formData = new FormData()
        formData.append('contentAnalyzerRequests', JSON.stringify(codePayload.contentAnalyzerRequests))
        const config = {
            file: curFile,
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_MLSENSEI}/predict`,
            method: 'POST',
            mode: 'cors',
            timeout: 90000,
            headers: getSenseiHeaders(),
            payload: codePayload,
            body: {
                formData: formData
            }
        }

        /* *********************************** */
        dispatch({ type: 'set-codes', payload: { config: config } })

        const responseBody = await ssApiSyncCall(config)
        const contentAnalyzerResponse = responseBody.get('contentAnalyzerResponse')
        const response = JSON.parse(responseBody.get('metadata'))
        const senseiMetadata = JSON.parse(contentAnalyzerResponse)
        const maskOut = senseiMetadata.statuses[0].invocations[0]['sensei:outputs'].mask

        // upload the image
        const output = responseBody.get(maskOut['sensei:multipart_field_name'])
        const blob = output.slice(0, output.size, 'image/png')
        const outputFileName = `maskrefinement_${uuid()}_${curFile.shortName}.png`
        const newFile = new File([blob], outputFileName, { type: 'image/png' })
        const outputHref = `${getTempDirectory()}/${outputFileName}`

        files.push({
            inputFile: curFile,
            name: getFilename(outputHref),
            shortName: getFilenameWithoutExt(outputHref),
            path: outputHref,
            outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
            output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
            outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(outputHref)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
        })

        await fileUpload(newFile, outputFileName)

        dispatch({
            type: 'skycity-success',
            payload: {
                files: files,
                response: response,
                loading: false,
                wait: false
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                inputSecondFiles.forEach(inputSecondFile => {
                    funcArray.push(start(inputFile, inputSecondFile))
                })
            })
            console.log('number of functions: ' + funcArray.length)

            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    console.log(`err: ${err}`)
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                    dispatch({
                        type: 'sensei-end-wait'
                    })
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function checkFileType (type) {
        if (type.toLowerCase() === 'jpg') {
            type = 'jpeg'
        }
        return type.startsWith('image/') ? type : `image/${type}`
    }

    function errorOccured (errMsg, res) {
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.msgTimeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <SenseiParams params={params} setParams={setParams} />
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
