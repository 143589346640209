import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'
import { SkycityContext } from '../../lib/SkycityProvider'

import { error } from '@react/react-spectrum/Toast'
import Button from '@react/react-spectrum/Button'

import ApplyEditOptions from '../Shared/ApplyEditOptions'
import OutputOptions from '../Shared/OutputOptions'

import { apiCall, getStatus } from '../../lib/cisApi.js'
import { getFilename, getFilenameWithoutExt, getHeaders, getStorage, getLrApplyEditOptions, getOptionsOutput, getEncodedURI } from '../../lib/skycity.js'

import axios from 'axios'

export default function Operation (files) {
    const {
        inputFiles,
        outputOptionsLr,
        lrApplyEditOptions,
        errorOptions,
        dispatch
    } = useContext(SkycityContext)

    function start (curFile) {
        /* *********************************** */
        const codePayload = {
            inputs: {
                source: {
                    href: curFile.path,
                    storage: getStorage(curFile)
                }
            },
            options: getLrApplyEditOptions(lrApplyEditOptions),
            outputs: getOptionsOutput(curFile, outputOptionsLr)
        }
        const config = {
            url: `${process.env.REACT_APP_CONFIG_ENDPOINT_ACR}/edit`,
            method: 'POST',
            timeout: 90000,
            headers: getHeaders(),
            payload: codePayload,
            body: JSON.stringify(codePayload)
        }
        /* *********************************** */

        dispatch({ type: 'set-codes', payload: { config: config } })

        apiCall(config, (id, json) => {
            if (id) {
                getStatus(id, config, (res, error, errResponse) => {
                    if (error) {
                        errorOccured(`${error} - ${JSON.stringify(curFile)}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'failed') {
                        const errMsg = res.outputs[0].errors ? `${id} - ${JSON.stringify(res.outputs[0].errors, null, 2)}` : `ERROR with ${config.url}`
                        errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, errResponse || res)
                    } else if (res && res.outputs && res.outputs[0] && res.outputs[0].status === 'succeeded') {
                        const rendition = res.outputs[0]._links.self
                        files.push({
                            inputFile: curFile,
                            name: getFilename(rendition.href),
                            shortName: getFilenameWithoutExt(rendition.href),
                            path: rendition.href,
                            outputThumbnail: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/:rendition;size=300?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                            output: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/:rendition;size=0?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`,
                            outputToDownload: `${process.env.REACT_APP_CONFIG_ENDPOINT_CC_STORAGE}/${getEncodedURI(rendition.href)}/?api_key=${process.env.REACT_APP_CONFIG_APP_KEY}&user_token=${window.adobeIMS.getAccessToken().token}`
                        })
                        dispatch({
                            type: 'skycity-success',
                            payload: {
                                files: files,
                                selectedInputFile: files[0],
                                showLayerInfoPane: true,
                                loading: false,
                                wait: false,
                                response: res
                            }
                        })
                    }
                })
            } else {
                const errMsg = json ? `${JSON.stringify(json, null, 2)}` : `ERROR with ${config.url}`
                errorOccured(`${JSON.stringify(curFile)} - ${errMsg}`, json)
            }
        })
    }

    function run (e) {
        try {
            dispatch({ type: 'skycity-run', payload: {} })

            files = []
            const funcArray = []
            inputFiles.forEach(inputFile => {
                funcArray.push(start(inputFile))
            })
            axios.all(funcArray)
                .then((res) => {
                    // then
                })
                .catch((err) => {
                    const errMsg = `ERROR: ${JSON.stringify(err)}`
                    errorOccured(errMsg)
                })
                .finally(() => {
                    console.log('finally...')
                })
        } catch (e) {
            error(`${__('error_msg_title')}: ${e}`, {
                timeout: 0
            })
            errorOccured(`${__('error_msg_title')}: ${e}`)
        }
    }

    function isRunDisabled () {
        if (inputFiles && inputFiles.length > 0) {
            return false
        }
        return true
    }

    function errorOccured (errMsg, res) {
        console.log(errMsg)
        error(errMsg.slice(0, errorOptions.wordSlice), {
            onClose: () => console.log('close'),
            timeout: errorOptions.timeout
        })
        dispatch({ type: 'error-occured', payload: { errMsg: errMsg, response: res, loading: false, wait: false } })
    }

    try {
        return <div>
            <ApplyEditOptions />
            <OutputOptions imageTypes={['image/jpeg', 'image/x-adobe-dng', 'image/png']}/>
            <div className="adobe-skycity-button-right">
                <Button variant="cta" onClick={e => run(e)} disabled={isRunDisabled()}>{__('btn_run')}</Button>
            </div>
        </div>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}
