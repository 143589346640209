import React, { useContext } from 'react'
import { __ } from 'i18n-for-browser'
import '../../config/i18n'

import { Tab, TabView } from '@react/react-spectrum/TabView'
import Alert from '@react/react-spectrum/Alert'

import { SkycityContext } from '../../lib/SkycityProvider'

import Response from './Response'
import OutputFiles from './OutputFiles'
import CodeSnippet from './CodeSnippet'
import CodeSnippetACRPredict from './CodeSnippetACRPredict'
import CodeSnippetSensei from './CodeSnippetSensei'
import CodeSnippetSsGenerate from './CodeSnippetSsGenerate'
import CodeSnippetSsAutotag from './CodeSnippetSsAutotag'
import CodeSnippetImageCutout from './CodeSnippetImageCutout'
import CodeSnippetCPFEngine from './CodeSnippetCPFEngine'

export default function Output ({
    response,
    outputFiles,
    codes,
    errMsg,
    error
}) {
    const {
        page,
        inputFiles
    } = useContext(SkycityContext)

    function getError () {
        if (error && inputFiles && inputFiles.length > 0) {
            return <Alert header="ERROR" variant="error" style={{ display: 'block' }}>{errMsg}</Alert>
        }
        return null
    }

    function getCodeSnippet () {
        if (page && page === 'sspieonsensei') {
            return <CodeSnippetSensei language="json" />
        }
        if (page && page === 'ssgeneration') {
            return <CodeSnippetSsGenerate language="json" />
        }
        if (page && page === 'ssautotag') {
            return <CodeSnippetSsAutotag language="json" />
        }
        if (page && page === 'ssimagecutout') {
            return <CodeSnippetImageCutout language="json" />
        }
        if (page && page === 'ssacrmasking') {
            return <CodeSnippetCPFEngine language="json" outputs={['masks', 'metadata', 'focal_range']} />
        }
        if (page && page === 'ssdistractorice') {
            return <CodeSnippetCPFEngine language="json" outputs={['outputs', 'metadata']} />
        }
        if (page && (page === 'ssimagecutoutonsensei' ||
            page === 'ssimagecutoutonsenseiasync' ||
            page === 'ssuniversalrefinement' ||
            page === 'ssmaskrefinement' ||
            page === 'sstrimapmatting')) {
            return <CodeSnippetCPFEngine language="json" outputs={['mask', 'metadata']} />
        }
        if (page && page === 'ssbodyparser') {
            return <CodeSnippetCPFEngine language="json" outputs={['image_out', 'json_out']} />
        }
        if (page && page === 'ssswappingautoencoder') {
            return <CodeSnippetCPFEngine language="json" outputs={['image_out']} />
        }
        if (page && (page === 'ssinteractiveselection' || page === 'ssshadowdetection' || page === 'ssobjectselection')) {
            return <CodeSnippetCPFEngine language="json" outputs={['mask_out', 'metadata_out']} />
        }
        if (page && (page === 'ssdomo')) {
            return <CodeSnippetCPFEngine language="json" outputs={['masks', 'metadata']} />
        }
        if (page && (page === 'sssshharmonization' || page === 'ssdepthestimation' || page === 'sscmganholefilling')) {
            return <CodeSnippetCPFEngine language="json" outputs={['image_out', 'metadata_out']}/>
        }
        if (page && page === 'ssshadowgenerator') {
            return <CodeSnippetCPFEngine language="json" outputs={['shadow_out', 'image_out', 'metadata_out']} />
        }
        if (page && (page === 'sssemanticharmonization' || page === 'sssuperresolution' || page === 'ssshadowremoval')) {
            return <CodeSnippetCPFEngine language="json" outputs={['image', 'metadata']} />
        }
        if (page && page === 'ssselectall') {
            return <CodeSnippetCPFEngine language="json" outputs={['masks_out', 'subpart_masks_out', 'metadata_out']} />
        }
        if (page && page === 'ssmetacaf') {
            return <CodeSnippetCPFEngine language="json" outputs={['output']} />
        }
        if (page && page === 'ssllava') {
            return <CodeSnippetCPFEngine language="json" outputs={['output, metadata']} />
        }
        if (page && page === 'ssdistractordetection') {
            return <CodeSnippetCPFEngine language="json" outputs={['metadata, predictions']} />
        }
        if (page && page === 'ssparametricharmonization') {
            return <CodeSnippetCPFEngine language="json" outputs={['metadata, rgb_curve, composite, shading_map']} />
        }
        if (page && page === 'sslayerscene') {
            return <CodeSnippetCPFEngine language="json" outputs={['metadata, detected_objects, completed_images']} />
        }
        if (page && page === 'ssentitysegmentation') {
            return <CodeSnippetCPFEngine language="json" outputs={['semantic_masks', 'stuff_masks', 'metadata']} />
        }
        if (page && page === 'sssegmentanything') {
            return <CodeSnippetCPFEngine language="json" outputs={['masks', 'segmentation_metadata', 'metadata']} />
        }
        if (page && page === 'ssstuffsegmentation') {
            return <CodeSnippetCPFEngine language="json" outputs={['masks', 'stuff_metadata', 'metadata']} />
        }
        if (page && page === 'lrmaskingpredict') {
            return <CodeSnippetACRPredict language="json" codes={codes}/>
        }

        return <CodeSnippet language="json" codes={codes} />
    }

    try {
        return <React.Fragment>
            {getError()}
            <TabView>
                {outputFiles && outputFiles.length > 0 && <Tab label="Output Files" disabled={!outputFiles || outputFiles.length === 0 }>
                    <OutputFiles outputFiles={outputFiles}/>
                </Tab>}
                <Tab label="Code" disabled={!codes || codes.length === 0 }>
                    {getCodeSnippet()}
                </Tab>
                <Tab label="Response" disabled={!response || response.length === 0}>
                    <Response response={response}/>
                </Tab>
            </TabView>
        </React.Fragment>
    } catch (e) {
        console.log(e)
        error(`${__('error_msg_title')}: ${e}`, {
            timeout: 0
        })
    }
}

// <Tab label="Response" disabled={!response || response.length === 0}>

// { (page && page === 'ssimagecutout') ? <CodeSnippetSensei language="json" /> : <CodeSnippet language="json" />}
