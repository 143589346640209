import {
    getHeaders,
    getStorage,
    getOptionsOutput,
    getInputFileFont
} from '../../lib/skycity.js'

import { getParams } from '../params/psSmartObjectV2.param.js'
import { prepareBodyForExternal } from './testHelper.js'

export function getConfig (curFile, outputOptions, iccProfileOption, inputSecondFiles, selectedLayers) {
    const payload = getCodePayload(curFile, outputOptions, iccProfileOption, inputSecondFiles, selectedLayers)
    const config = {
        url: `${process.env.REACT_APP_CONFIG_ENDPOINT_PIE}/psdService/smartObjectV2`,
        method: 'POST',
        timeout: 400000,
        headers: getHeaders(),
        payload: payload,
        body: JSON.stringify(payload)
    }
    return config
}

function getCodePayload (curFile, outputOptions, iccProfileOption, inputSecondFiles, selectedLayers) {
    const layers = selectedLayers.filter(layer => layer.type === 'smartObject').map(layer => {
        return {
            id: layer.id,
            input: {
                href: inputSecondFiles.path || inputSecondFiles.href,
                storage: getStorage(inputSecondFiles)
            }
        }
    })
    const codePayload = {
        inputs: [{
            href: curFile.path || curFile.href,
            storage: getStorage(curFile)
        }],
        options: {
            layers: layers && layers.length > 0 ? layers : selectedLayers
        },
        outputs: getOptionsOutput(curFile, outputOptions, iccProfileOption)
    }
    if (inputSecondFiles && inputSecondFiles.length > 0) {
        codePayload.options.fonts = getInputFileFont(inputSecondFiles)
    }
    return codePayload
}

// ************************************************************************
// Test Cases
// ************************************************************************
export async function getTestCases (storages) {
    const testCases = await getParams(storages)
    const newTestCases = await Promise.all(testCases.map(async (testCase) => {
        testCase = await prepareBodyForExternal(testCase)
        testCase.area = 'Photoshop API'
        testCase.feature = 'psSmartObjectV2'
        return testCase
    }))
    return newTestCases
}
